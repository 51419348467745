/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */



/* GENERAL */
.bold {
	font-weight: bold;
}

.mr-1 {
	margin-right: 0.5rem !important;
}

.ml-1 {
	margin-left: 0.5rem !important;
}

.mt-45 {
	margin-top: 0.45rem !important;
}

.mt-label {
	margin-top: 24px !important;
}

.show>.dropdown-menu {
	padding: 0;
}

@media (max-width: 768px) {
	.brand-logo {
		display: block;
		width: 30px;
		overflow: hidden;
	}
}



/* TABLE */
.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: auto !important;
}



/* FORM CONTROL */
.form-label {
	line-height: 24px;
	margin: 0;
}

.form-control,
.form-select {
	font-size: 12px;
	line-height: 24px;
	padding: 6px 10px;
}

textarea.form-control {
	padding: 6px 10px;
	resize: none;
}

.form-check-label.form-label {
	font-size: 0.8rem;
	line-height: 20px;
}

.valid-field {
	border: 1px solid #28C76F;
}

.invalid-field {
	border: 1px solid rgb(207, 1, 1);
}


/* CARD */
.card .card-header h4 {
	margin: 0;

	svg {
		float: left;
		font-size: 16px;
		margin: 0 10px 0 0;
	}
}

.card-inner-yellow {
	background: #FFFAEF;
}

.card-inner-green {
	background: #316E5C;
}



/* MODAL */
.modal-codee {
	.modal-header {
		display: block;
		background: transparent;

		.btn-close {
			position: absolute;
			right: 1.4rem;
			top: 0.8rem;
		}
	}

	.modal-title {
		display: block;
		font-size: 28px;
		font-weight: 500;
		line-height: 34px;
		padding: 0.8rem 0;
		text-align: center;
	}

	.modal-footer {
		border: 0;

		justify-content: center;

		.btn {
			margin: 0.25rem 0.75rem;
		}
	}
}



/* CUSTOMER */
.customer-header {
	position: relative;
	border-radius: 0.428rem 0.428rem 0 0;
	padding: 2rem 1.5rem 0 1.5rem;
	overflow: hidden;
}

.customer-header .mask {
	position: absolute;
	left: 0;
	top: 0;

	background: #DFDBD1;
	width: 100%;
	z-index: 5;
}

.customer-header .mask.h70 {
	height: 70px;
}

.customer-header .mask.h80 {
	height: 80px;
}

.customer-header .title {
	display: flex;

	position: relative;
	z-index: 10;

	align-items: flex-start;
}

.customer-header .title .text {
	padding: 20px 0 0 0;
}

.customer-header .title .text-list {
	padding: 15px 0 0 0;
}

.customer-header .title h3 {
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	margin-bottom: 0.75rem;
}

.customer-header .title h4 {
	font-size: 18px;
	font-weight: bold;
	line-height: 24px;
	margin-bottom: 0.75rem;
}

.customer-header .title p {
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 0.25rem;
}


.progress-bar-customer {
	height: 24px;
	line-height: 24px;

	.progress-bar {
		height: 24px;
		line-height: 24px;
	}
}


.avatar-icon {
	display: flex;

	width: 48px;
	height: 48px;

	align-items: center;
	justify-content: center;
}

.info-icon h4 {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

.info-icon p {
	font-size: 12px;
	margin: 0;
}


.table-progress thead th {
	font-size: 10px;
	border: 0;
}

.table-progress tbody td {
	font-size: 12px;
	background: #FFF;
	border-bottom: 5px solid #FFFAEF;
	height: 55px;
}

.table-progress thead th:first-child,
.table-progress tbody td:first-child {
	padding: 0.72rem 2rem 0.72rem 0.72rem;
}

.table-progress thead th:last-child,
.table-progress tbody td:last-child {
	padding: 0.72rem 0.72rem 0.72rem 2rem;
}


.table-evaluation thead th {
	border: 0;
}

.table-evaluation tbody td {
	background: #FFF;
	border-bottom: 5px solid #FFFAEF;
}

.table-evaluation thead th,
.table-evaluation tbody td {
	padding: 1.5rem 2rem;
}







/* CODEE */
.codee-v-separator {
	display: inline-block;
	border-left: 2px solid #D8D6DE;
	height: 20px;
	margin: 0 15px;
}

@media (max-width: 768px) {
	.codee-v-separator {
		display: block;
		border-left: 0;
		width: 100%;
		height: 5px;
	}
}

.codee-shadow {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
}


.codee-add-full {
	border-style: dashed !important;
	width: 100%;
	height: 392px;
}


.codee-steps {
	display: flex;

	width: 100%;

	align-items: center;
	justify-content: space-between;

	.step {
		display: flex;
		position: relative;
		width: auto;
		min-height: 38px;
		padding: 0 25px 0 50px;

		align-items: center;

		p {
			line-height: 18px;
			margin: 0;
			padding: 1px;
		}

		svg {
			position: absolute;
			right: 0;
		}

		.btn-icon {
			position: absolute;
			left: 0;
			top: 0;
			width: 38px;
			height: 38px;
		}

		.btn-icon.btn-secondary {
			color: #807D77 !important;
			background-color: #DFDBD1 !important;
		}
	}

	.step:last-child {
		padding: 0 0 0 50px;
	}
}

.codee-steps.left {
	display: block;

	.step {
		float: left;
		margin-right: 30px;
	}

	.step:last-child {
		margin-right: 0;
	}
}

@media (max-width: 768px) {
	.codee-steps.left {
		.step {
			clear: both;
			margin: 0 0 5px 0;
		}
	}
}


.codee-file-upload {
	background: transparent;
	border-style: dashed !important;
	box-shadow: 0 0 0;
	padding: 20px 0;
	text-align: center;

	p {
		font-size: 11px;
		margin: 0;

		span {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.or {
		position: relative;
		display: block;
		width: calc(100% - 30px);
		height: 15px;
		margin: 0 15px 15px 15px;

		span {
			position: absolute;
			left: 50%;
			top: 5px;
			font-size: 12px;
			padding: 0 5px;
			transform: translate(-50%, 0);
		}
	}
}

.dark-layout .codee-file-upload {
	.or {
		.bg-white {
			background-color: #283046 !important;
		}
	}
}


.codee-action {
	display: flex;

	height: 100%;

	align-items: flex-end;
	justify-content: flex-end;

	.btn {
		margin-left: 15px;
	}
}


.codee-actions-buttons {
	display: flex;

	justify-content: space-between;

	svg {
		margin: 0 0 0 5px;
	}
}


.codee-conclude-buttons {
	.btn {
		margin: 0 15px;
	}
}


.codee-thumb-table {
	display: block;
	border-radius: 5px;
	width: 48px;
	height: 48px;
	overflow: hidden;
}

.codee-thumb-photo {
	float: left;
	border-radius: 5px;
	width: 100px;
	height: 100px;
	margin: 0 15px 0 0;
	overflow: hidden;
}

.codee-file-buttons {
	display: flex;

	float: left;
	height: 100px;

	align-items: flex-end;

	.upload {
		position: relative;
		float: left;

		input {
			position: absolute;
			left: 0;
			bottom: 0;
			opacity: 0;
		}
	}

	.ml-15 {
		margin-left: 15px;
	}
}


.codee-videos {
	margin: 0 -15px;

	.video {
		float: left;
		border-radius: 5px;
		width: calc(20% - 30px);
		margin: 0 15px 30px 15px;
		overflow: hidden;

		.thumb {
			display: flex;

			color: #BABFC7;
			background: #F8F8F8;
			height: 100px;

			align-items: center;
			justify-content: center;
		}

		p {
			padding: 0 10px;
		}
	}
}

@media (max-width: 992px) {
	.codee-videos {
		.video {
			width: calc(33% - 30px);
		}
	}
}

@media (max-width: 768px) {
	.codee-videos {
		.video {
			width: calc(50% - 30px);
		}
	}
}

.thumb-question {
	display: flex;

	background: #FFFAEF;
	border-radius: 4px;
	width: 90px;
	height: 60px;

	align-items: center;
	justify-content: center;
}

.table-drag-drop tbody tr {
	cursor: move;
}

.react-dataTable>div {
	//Para correção problema botões (Editar/Excluir)
	overflow-y: unset;
	overflow-x: unset;
}

@media (max-width: 768px) {

	//Para correção problema botões (Editar/Excluir)
	.react-dataTable>div {
		overflow-y: hidden;
		overflow-x: auto;
	}
}

.checkbox-codee {
	position: relative;
	// margin: 0 0 10px 0;
	padding: 0 0 0 28px;
	display: flex;
	min-width: 100px;
	gap: 4px;

	input {
		width: 18px;
		height: 18px;
	}

	label {
		line-height: 20px;
	}
}

.add-option-checkbox {
	display: flex;
	width: 450px;
	label {
		line-height: 20px;
		margin: 0 4px;
	}
}

.image-render-container {
    display: block;
	position: relative;
    width: 360px;
    max-height: 360px;
	padding: 10px;
}

.image-render-container img {
	max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
	padding: 10px;
    // border-radius: 10px;
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
	.image-render-container {
        width: 100%; /* para que o conteúdo se ajuste à largura da tela */
        max-width: 100%; /* assegura que o conteúdo não exceda a largura da tela */
    }
}

  @media print {
	/* Ajustes da página */
	@page {
		margin: 0;
		margin-top: 15mm;
		// size: 510px 720px;
	}

	/* Oculta todos os elementos */
	body * {
		visibility: hidden;
	}

	/* Ajuste do body para remover background */
	body {
		background: none !important;
	}

	/* Exibe apenas o elemento específico e seus filhos */
	.printable_content,
	.printable_content * {
		visibility: visible;
	}

	/* Ajustes da posição do template para impressão */
	.printable_content {
		width: 100%;
		height: 100%;
		margin-top: -220px;
	}

	.page-break {
		page-break-before: always;
	}

	.avoid-page-break {
		page-break-inside: avoid;
	}
  }